import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA';
import { Button, Box } from '@firsttable/web-components/atoms';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import { errorMessage, scrollTo } from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import NavButton from '../../components/atoms/NavButton';

const UPDATE_USER_LOST_PASSWORD = gql`
  mutation updateUserLostPassword($email: String!, $siteId: Int!) {
    updateUserLostPassword(email: $email, siteId: $siteId) {
      email
    }
  }
`;

const ForgotPasswordForm = ({ handleSubmit, isSubmitting, status }) => (
  <FormLayout handleSubmit={handleSubmit} status={status}>
    <Field
      component={FormField}
      mb={['15px', '20px']}
      id="email"
      name="email"
      label="Enter the email address associated with your First Table account and we’ll email you a link to reset your password."
      placeholder="Enter email"
      type="email"
    />
    <Box mb="20px">
      <Button size="l" wide kind="cta" type="submit" isLoading={isSubmitting}>
        Send password reset link
      </Button>
    </Box>
  </FormLayout>
);

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
ForgotPasswordForm.defaultProps = {
  isSubmitting: false,
  status: {},
};

const ConnectedForgotPasswordForm = compose(
  apolloGraphql(UPDATE_USER_LOST_PASSWORD, { name: 'updateUserLostPassword' }),
  withFormik({
    mapPropsToValues: () => ({ email: '' }),
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    handleSubmit: async (
      values,
      {
        props: { updateUserLostPassword, siteId },
        setSubmitting,
        resetForm,
        setStatus,
      },
    ) => {
      try {
        const { error } = await updateUserLostPassword({
          variables: { email: values.email, siteId },
        });
        if (!error) {
          resetForm();
          setStatus({
            message: 'A password reset link has been emailed to you',
            type: 'success',
          });
        } else {
          setStatus({ message: error.message, type: 'danger' });
        }
        scrollTo('form-alert', -20);
      } catch (e) {
        setStatus({
          message: errorMessage(
            e,
            'There was an error sending your password reset link email',
          ),
          type: 'danger',
        });
        scrollTo('form-alert', -20);
        setSubmitting(false);
      }
    },
  }),
)(ForgotPasswordForm);

const ForgotPassword = ({ data: { siteConfig } }) => (
  <TwoColumnLayout
    title="Forgot Password?"
    col1={<ConnectedForgotPasswordForm siteId={siteConfig.siteId} />}
    col2={<ContactCTA NavButtonComponent={NavButton} />}
  />
);

ForgotPassword.propTypes = {
  data: PropTypes.shape(),
};
export default ForgotPassword;

export const query = graphql`
  query lostPasswordSiteID {
    siteConfig {
      siteId
    }
  }
`;
